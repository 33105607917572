/* Kern problematic letters in .Header-quote */
var kernTextEl = document.querySelectorAll("[data-kern]");
kernTextEl.forEach(function(instance) {
  instance.innerHTML = instance.innerHTML.replace(/(f)(ö)/gi,"<i class='Header-kern'>$1</i>$2");
})

/* Append underline SVG to appropriate .Header-quote <span> */
var quoteUnderline = document.querySelector("[data-quote='underline']")
var quoteSpan = document.querySelector("[data-quote='container'] span");
if (quoteUnderline !== null && quoteSpan !== null) {
  var quoteSVG = quoteUnderline.innerHTML;

  quoteSpan.innerHTML += quoteSVG;
  quoteUnderline.remove();
}

/* Device class */
if ("ontouchstart" in document.documentElement) {
  document.body.classList.add("Device--touch");
} else {
  document.body.classList.add("Device--pointer");
}

if (typeof(Storage) !== "undefined") {
  try {
    var theme = sessionStorage.getItem("theme");
    if (theme == null) {
      var randomSession = Math.floor(Math.random()*4 + 1);
      sessionStorage.setItem("theme", randomSession);
    }
    var randomTheme = "color-" + sessionStorage.getItem("theme");
    document.body.classList.add(randomTheme);
  } catch (error) {
    /* Fallback to the first color if Storage is not supported */
    document.body.classList.add("color-1");
  }
}

var inviewActiveClass = "item--is-inview";
inView.threshold(0.25);

var eeLazy = new LazyLoad({
  elements_selector: "[data-src]",
  callback_load: function(element) {
    if (inView.is(element)) {
      element.parentNode.parentNode.classList.add(inviewActiveClass);
    }
  }
});

var rellaxObj = document.querySelectorAll("[data-rellax]");
if (rellaxObj.length > 0) {
  var rellax = new Rellax("[data-rellax]", {
    center: true,
    speed: 1
  });
}

var inviewHook = "[data-inview-draw]";
var inviewEls = document.querySelectorAll(inviewHook);
var inviewSVG = document.querySelectorAll("[data-inview-draw] svg");
var inviewAnim = [];

console.log(inviewSVG);

inviewSVG.forEach(function(instance, index) {
  inviewAnim[index] = new Vivus(instance, {
    type: "oneByOne",
    duration: instance.getAttribute("data-vivus-duration") || 250,
    pathTimingFunction: function(t) {
      /* easeOutQuad */
      return t*(2-t);
    },
    start: "manual"
  });
});

inView(inviewHook).on("enter", function(element) {
  var currentIndex = [].indexOf.call(inviewEls, element);

  element.classList.add(inviewActiveClass);
  inviewAnim[currentIndex].reset().play();
});
inView(inviewHook).on("exit", function(element) {
  element.classList.remove(inviewActiveClass);
});

var hoverSVG = document.querySelectorAll("[data-hover-vivus]");
var hoverTrigger = document.querySelectorAll("[data-hover-underline]");
var hoverAnim = [];
hoverSVG.forEach(function(instance, index) {
  hoverAnim[index] = new Vivus(instance, {
    duration: instance.getAttribute("data-hover-vivus") || 100,
    pathTimingFunction: function(t) {
      /* easeOutQuad */
      return t*(2-t);
    },
    start: "manual"
  });
});

hoverTrigger.forEach(function(instance, index) {
  instance.addEventListener("mouseenter", function(event) {
    var currentIndex = [].indexOf.call(hoverTrigger, instance);

    setTimeout(function() {
      hoverAnim[currentIndex].play();
    }, 300);
  });

  instance.addEventListener("mouseleave", function(event) {
    var currentIndex = [].indexOf.call(hoverTrigger, instance);

    hoverAnim[currentIndex].play(-3);
  });
});

inView("[data-appear]").on("enter", function(element) {
  return element.classList.add(inviewActiveClass);
});
inView("[data-appear]").on("exit", function(element) {
  return element.classList.remove(inviewActiveClass);
});

/* Waypoints */
var passedObj = document.querySelectorAll("[data-passed]");
passedObj.forEach(function(instance, index) {
  new Waypoint({
    element: instance,
    handler: function(direction) {
      document.body.classList.toggle(this.element.dataset.passed + "--passed", direction == "down");
    },
    /* Top hits the middle of the viewport */
    offset: "50%"
  })
})

var reachedObj = document.querySelectorAll("[data-reached]");
reachedObj.forEach(function(instance, index) {
  new Waypoint({
    element: instance,
    handler: function(direction) {
      document.body.classList.toggle(this.element.dataset.reached + "--reached", direction == "down");
    },
    /* Top hits the middle of the viewport */
    offset: "15%"
  })
})

var stickyObj = document.querySelectorAll("[data-sticky]");
stickyObj.forEach(function(instance, index) {
  new Waypoint({
    element: instance,
    handler: function(direction) {
      document.body.classList.toggle(this.element.dataset.sticky + "--sticky", direction == "down");
    }
  })
})

/* Smooth scroll */
var scrollAnchors = document.querySelectorAll("[href^='#']");
scrollAnchors.forEach(function(instance) {
  instance.addEventListener("click", function(event) {
    event.preventDefault();
    var target = document.querySelector(this.getAttribute("href"));

    window.scrollTo({
      top: target.offsetTop,
      behavior: "smooth"
    });
  });
})

// var letteringVivusEl = document.querySelectorAll("[data-lettering] svg");
// var letteringVivus = [];
// letteringVivusEl.forEach(function(instance, index) {
//   letteringVivus[index] = new Vivus(instance, {
//     type: "oneByOne",
//     duration: instance.getAttribute("data-vivus-duration") || 250,
//     animTimingFunction: function(t) {
//       /* easeOutSine */
//       return Math.sin(Math.PI / 2 * t);
//     }
//   });
// })
